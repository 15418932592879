import { Injectable, NgZone, inject } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { ApiResponse } from '@/models/api';
import { environment as ENV } from '@environments/environment';
import { StorageService } from '@/modules/core/services/storage.service';
import { AuthenticatedUserResponse } from '@/modules/auth/models/responses/authenticated-user-response.model';
import { LoginRequest } from '@/modules/auth/models/requests/login-request.model';
import { LoginResponse } from '@/modules/auth/models/responses/login-response.model';
import { UserRole } from '@/modules/auth/constants/user-role.constant';
import { UserPermission } from '../constants/user-permission.constant';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly URL_BY_ROLE: {
    [key in UserRole]: string;
  } = {
    ADMIN_ROLE: '/transactions',
    CUSTOMER_SUPPORT_ROLE: '/customers',
  };
  private userMe: BehaviorSubject<AuthenticatedUserResponse | null> =
    new BehaviorSubject<AuthenticatedUserResponse | null>(null);

  public userMe$: Observable<AuthenticatedUserResponse | null> =
    this.userMe.asObservable();

  private readonly router: Router = inject(Router);
  private readonly ngZone: NgZone = inject(NgZone);
  private readonly http: HttpClient = inject(HttpClient);
  private readonly storageService: StorageService = inject(StorageService);

  public login(login: LoginRequest): Observable<ApiResponse<LoginResponse>> {
    return this.http.post<ApiResponse<LoginResponse>>(
      `${ENV.apiUrl}/v1/auth/login/panel`,
      login
    );
  }

  public logout(): void {
    this.storageService.removeAll();
    this.redirectToLogin();
  }

  public me(): Observable<ApiResponse<AuthenticatedUserResponse>> {
    return this.http.get<ApiResponse<AuthenticatedUserResponse>>(
      `${ENV.apiUrl}/v1/auth/me`
    );
  }

  public isLoggedIn(): boolean {
    return !!this.storageService.get('accessToken');
  }

  public saveAccessToken(accessToken: string): void {
    this.storageService.set('accessToken', accessToken);
  }

  public getAccessToken(): string {
    return this.storageService.get('accessToken');
  }

  public getUserRoles(): Array<UserRole> {
    const me = this.userMe.getValue();
    if (me) {
      return me.roles;
    }

    return [];
  }

  public getUserPermissions(): Array<UserPermission> {
    const me = this.userMe.getValue();
    if (me) {
      return me.permissions;
    }

    return [];
  }

  public userHasRoles(roles: Array<UserRole>): boolean {
    return this.getUserRoles().some((role: UserRole) => roles.includes(role));
  }

  public userHasPermissions(permissions: Array<UserPermission>): boolean {
    return this.getUserPermissions().some((permission: UserPermission) =>
      permissions.includes(permission)
    );
  }

  public setUserMe(user: AuthenticatedUserResponse): void {
    this.userMe.next(user);
  }

  public redirectToHome(): void {
    const userRole = this.getUserRoles()[0] || 'CUSTOMER_SUPPORT_ROLE';
    this.ngZone.run(() => this.router.navigate([this.URL_BY_ROLE[userRole]]));
  }

  public redirectToLogin(): void {
    this.ngZone.run(() => this.router.navigateByUrl('/auth/login'));
  }
}
